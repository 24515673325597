import React from "react";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  image: {
    alignSelf: "center",
    height: "auto",
    width: "100%"
  },
  root: {
    display: "flex",
    flexDirection: "column",
    margin: 16,
    maxWidth: "calc(25% - 32px)",
    minWidth: 100
  }
}));

function SocialButton({ image, url }) {
  const classes = useStyles();

  return (
    <a href={url} target="_blank" className={classes.root}>
      <img className={classes.image} src={image} />
    </a>
  );
}

SocialButton.propTypes = {
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default SocialButton;
