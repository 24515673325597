import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import cx from "classnames";
import SEO from "../components/seo";
import CoreValue from "../components/home/CoreValue";
import ProgressArrow from "../components/home/ProgressArrow";
import ProgressItem from "../components/home/ProgressItem";
import SocialButton from "../components/home/SocialButton";
import logo from "../images/logoWhite.png";
import team from "../images/team.png";
import progressAnalyse from "../images/serviceAnalysing.png";
import progressDesign from "../images/serviceDesign.png";
import progressEntwicklung from "../images/serviceDevelopment.png";
import progressTesting from "../images/serviceTesting.png";
import progressVeroeffentlichung from "../images/servicePublishing.png";
import facebook from "../images/facebook.png";
import twitter from "../images/twitter.png";
import instagram from "../images/insta.png";
import valueFun from "../images/valueFun.png";
import valueSurprising from "../images/valueSurprising.png";
import valueTransparency from "../images/valueTransparency.png";
import valueQuality from "../images/valueQuality.png";
import { Link } from "gatsby-theme-material-ui";

const useStyles = makeStyles(theme => ({
  backgroundColor: {
    backgroundColor: theme.palette.secondary.main
  },
  buttonMore: {
    alignSelf: "center",
    marginTop: 16
  },
  flexWrapCenter: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  logo: {
    alignSelf: "center",
    height: "auto",
    maxWidth: "100%"
  },
  paddingAll: {
    padding: "70px 15px"
  },
  team: {
    alignSelf: "center",
    height: "auto",
    marginBottom: -70,
    marginTop: 16,
    width: "100%"
  },
  textBlockContent: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxWidth: 960,
    width: "100%"
  },
  textCentered: {
    color: theme.palette.secondary.main,
    marginBottom: 16,
    textAlign: "center"
  },
  textWhiteCentered: {
    color: "#fff",
    marginBottom: 16,
    textAlign: "center"
  }
}));

function Home() {
  const classes = useStyles();

  return (
    <>
      <SEO title={"Home"} />
      <div className={cx(classes.paddingAll, classes.backgroundColor)}>
        <div className={classes.textBlockContent}>
          <img className={classes.logo} src={logo} />
          <Typography className={classes.textWhiteCentered} variant="headline">
            Hi! Wir sind Vollkorn Games.
          </Typography>
          <Typography
            className={classes.textWhiteCentered}
            variant="subheading"
          >
            Wir sind ein gemischtes Team aus Hannover und wir lieben Spiele -
            also machen wir sie. Ausgestattet mit einer guten Portion Skill,
            Leidenschaft im Überfluss und einem Hauch von Wahnsinn wollen wir
            mit unseren Spielen nichts weiter als die Welt erobern.
          </Typography>
          <img className={classes.team} src={team} />
        </div>
      </div>
      <div className={classes.paddingAll}>
        <div className={classes.textBlockContent}>
          <Typography className={classes.textCentered} variant="headline">
            Unser Prozess
          </Typography>
          <div className={classes.flexWrapCenter}>
            <ProgressItem image={progressAnalyse} title="Analyse & Angebot" />
            <ProgressArrow />
            <ProgressItem
              image={progressDesign}
              title="Layout- & Designkonzept"
            />
            <ProgressArrow />
            <ProgressItem image={progressEntwicklung} title="Entwicklung" />
            <ProgressArrow />
            <ProgressItem image={progressTesting} title="Testphase" />
            <ProgressArrow />
            <ProgressItem
              image={progressVeroeffentlichung}
              title="Veröffentlichung oder Abgabe"
            />
          </div>
          <Button
            className={classes.buttonMore}
            variant="outlined"
            color="secondary"
            component={Link}
            to={"/services"}
          >
            Mehr
          </Button>
        </div>
      </div>
      <div className={cx(classes.paddingAll, classes.backgroundColor)}>
        <div className={classes.textBlockContent}>
          <Typography className={classes.textWhiteCentered} variant="headline">
            Besuch uns auch auf
          </Typography>
          <div className={classes.flexWrapCenter}>
            <SocialButton
              image={facebook}
              url="https://www.facebook.com/Vollkorn-Games-1809386565742782/"
            />
            <SocialButton
              image={twitter}
              url="https://twitter.com/VollkornGames"
            />
            <SocialButton
              image={instagram}
              url="https://www.instagram.com/vollkorngames/"
            />
          </div>
        </div>
      </div>
      <div className={classes.paddingAll}>
        <div className={classes.textBlockContent}>
          <Typography className={classes.textCentered} variant="headline">
            Unsere Kornwerte
          </Typography>
          <Typography className={classes.textCentered} variant="subheading">
            Jede Entscheidung unterliegt unseren Kornwerten. Egal ob es neue
            Projekte, Reboots, Auftragsarbeiten sind, sie müssen zu unseren
            Werten stehen. Nur so können wir ein Konsistentes Ergebnis erzielen
            und unseren Standard gerecht werden.
          </Typography>
          <div className={classes.flexWrapCenter}>
            <CoreValue
              image={valueFun}
              title="Spaßig"
              description="Die Benutzung ist, aus unserer Sicht, unterhaltsam sowohl kurzfristig als auch längerfristig."
            />
            <CoreValue
              image={valueSurprising}
              title="Überraschend"
              description="Es gibt noch den mystischen Aspekt. Unentdeckte Aspekte entfalten sich nach und nach."
            />
            <CoreValue
              image={valueTransparency}
              title="Transparent"
              description="Transparenz bei Monetarisierungsmodellen und über den allgemeinen Entwicklungsprozess."
            />
            <CoreValue
              image={valueQuality}
              title="Qualitativ"
              description="Standard bleibt erhalten indem die Kunstrichtung kohärent ist. Entspricht dem technischen Standard."
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
