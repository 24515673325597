import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import * as PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  image: {
    alignSelf: "center",
    height: "auto",
    marginBottom: 16,
    width: "100%"
  },
  root: {
    display: "flex",
    flexDirection: "column",
    margin: 16,
    maxWidth: 200
  },
  textCentered: {
    color: theme.palette.secondary.main,
    marginBottom: 16,
    textAlign: "center"
  }
}));

function CoreValue({ image, title, description }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.image} src={image} />
      <Typography className={classes.textCentered} variant="title">
        {title}
      </Typography>
      <Typography className={classes.textCentered} variant="body1">
        {description}
      </Typography>
    </div>
  );
}

CoreValue.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default CoreValue;
