import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import * as PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  image: {
    alignSelf: "center",
    height: "auto",
    marginBottom: 16,
    width: "100%"
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    margin: 16,
    maxWidth: "calc(20% - 32px - 48px)",
    minWidth: 100,
    [theme.breakpoints.down("md")]: {
      maxWidth: "calc(20% - 32px)"
    }
  },
  textCentered: {
    color: theme.palette.secondary.main,
    textAlign: "center"
  }
}));

function ProgressItem({ image, title }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.image} src={image} />
      <Typography className={classes.textCentered} variant="body2">
        {title}
      </Typography>
    </div>
  );
}

ProgressItem.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ProgressItem;
