import React from "react";
import { makeStyles } from "@material-ui/core";
import arrow1 from "../../images/arrow1.png";
import arrow2 from "../../images/arrow2.png";
import arrow3 from "../../images/arrow3.png";

const useStyles = makeStyles(theme => ({
  image: {
    height: 16,
    marginRight: 4,
    width: "33%"
  },
  root: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "row",
    maxHeight: 16,
    maxWidth: 48,
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  }
}));

function ProgressArrow() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.image} src={arrow1} />
      <img className={classes.image} src={arrow2} />
      <img className={classes.image} src={arrow3} />
    </div>
  );
}

export default ProgressArrow;
